import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/layout/BaseLayout.vue"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/dashboard/index.vue"),
                meta: {access_permissions: ["1", "2", "3"],title: "الداشبورد"}
            },

            {
                path: "/add-news",
                name: "add-news",
                component: () => import("@/views/news/add.vue"),
                meta: {access_permissions: ["1", "2", "3"],title: "إضافة خبر جديد"}
            },
            {
                path: "/all-news",
                name: "all-news",
                component: () => import("@/views/news/index.vue"),
                meta: {access_permissions: ["1", "2", "3"],title: "الأخبار"}
            },
            {
                path: "/edit-news/:news_id",
                name: "edit-news",
                component: () => import("@/views/news/edit.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "تعديل الخبر"}
            },
            {
                path: "/deleted-news",
                name: "deleted-news",
                component: () => import("@/views/news/trash.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: ""}
            },
            {
                path: "/categories",
                name: "categories",
                component: () => import("@/views/categories/index.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "التصنيفات"}
            },
            {
                path: "/add-category",
                name: "add-category",
                component: () => import("@/views/categories/add.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "إضافة تصنيف"}
            },
            {
                path: "/edit-category/:id",
                name: "edit-category",
                component: () => import("@/views/categories/edit.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "تعديل التصنيف"}
            },
            {
                path: "/articles",
                name: "articles",
                component: () => import("@/views/articles/index.vue"),
                meta: {access_permissions: ["1", "2", "3", "4", "5"], title: "المقالات"}
            },
            {
                path: "/add-article",
                name: "add-article",
                component: () => import("@/views/articles/add.vue"),
                meta: {access_permissions: ["1", "2", "3", "4", "5"], title: "إضافة مقال"}
            },
            {
                path: "/edit-article/:article_id",
                name: "edit-article",
                component: () => import("@/views/articles/edit.vue"),
                meta: {access_permissions: ["1", "2", "3", "4", "5"], title: "تعديل المقال"}
            },
            {
                path: "/play-list",
                name: "play-list",
                component: () => import("@/views/playList/index.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "قوائم التشغيل"}
            },
            {
                path: "/add-video",
                name: "add-video",
                component: () => import("@/views/playList/addVideo.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "إضافة فيديو"}
            },
            {
                path: "/albums",
                name: "albums",
                component: () => import("@/views/albums/index.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "معارض الصور"}
            },
            {
                path: "/create-album",
                name: "create-album",
                component: () => import("@/views/albums/add.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "إنشاء ألبوم صور"}
            },
            {
                path: "/breaking-news",
                name: "breaking-news",
                component: () => import("@/views/breaking_news/index.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "الأخبار العاجلة"}
            },
            {
                path: "/add-breaking-news",
                name: "add-breaking-news",
                component: () => import("@/views/breaking_news/add.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "إضافة خبر عاجل"}
            },
            {
                path: "/breaking-news/:id",
                name: "edit-breaking-news",
                component: () => import("@/views/breaking_news/edit.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "تعديل الخبر"}
            },
            {
                path: "/all-tags",
                name: "all-tags",
                component: () => import("@/views/tags/index.vue"),
                meta: {access_permissions: ["1", "2", "3", "4"], title: "الوسوم"}
            },
            {
                path: "/add-tag",
                name: "add-tag",
                component: () => import("@/views/tags/add.vue"),
                meta: {access_permissions: ["1", "2", "3", "4"], title: "إضافة وسم جديد"}
            },
            {
                path: "/edit-tag/:tag_id",
                name: "edit-tag",
                component: () => import("@/views/tags/edit.vue"),
                meta: {access_permissions: ["1", "2", "3", "4"], title: "تعديل الوسم"}
            },
            {
                path: "/live-broadcasts",
                name: "live-broadcasts",
                component: () => import("@/views/broadcasts/index.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "البثوث"}
            },
            {
                path: "/add-live-broadcast",
                name: "add-live-broadcasts",
                component: () => import("@/views/broadcasts/add.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "إضافة بث جديد"}
            },
            {
                path: "/users",
                name: "users",
                component: () => import("@/views/users/index.vue"),
                meta: {access_permissions: ["1", "2"], title: "المستخدمين"}
            },
            {
                path: "/add-user",
                name: "add-user",
                component: () => import("@/views/users/add.vue"),
                meta: {access_permissions: ["1", "2"], title: "إضافة مستخدم"}
            },
            {
                path: "/edit-user/:user_id",
                name: "edit-user",
                component: () => import("@/views/users/edit.vue"),
                meta: {access_permissions: ["1", "2"],title:"تعديل المستخدم"}
            },
            {
                path: "/settings",
                name: "settings",
                component: () => import("@/views/settings/index.vue"),
                meta: {access_permissions: ["1", "2"],title:"الإعدادات"}
            },
            {
                path: "/ads",
                name: "ads",
                component: () => import("@/views/ads/index.vue"),
                meta: {access_permissions: ["1", "2"],title:"الوحدات الإعلانية"}
            },
            {
                path: "/places",
                name: "places",
                component: () => import("@/views/ads/adsPlaces.vue"),
                meta: {access_permissions: ["1", "2"],title:"الأماكن الإعلانية"}
            },
            {
                path: "/add-place",
                name: "add-place",
                component: () => import("@/views/ads/addPlaceForm.vue"),
                meta: {access_permissions: ["1", "2"], title: "إضافة مكان إعلاني جديد"}
            },
            {
                path: "/edit-place/:place_id",
                name: "edit-place",
                component: () => import("@/views/ads/editPlaceForm.vue"),
                meta: {access_permissions: ["1", "2"], title: "تعديل المكان الإعلاني"}
            },
            {
                path: "/add-ads",
                name: "add-ads",
                component: () => import("@/views/ads/add.vue"),
                meta: {access_permissions: ["1", "2"], title: "إضافة إعلان جديد"}
            },
            {
                path: "/edit-ads/:id",
                name: "edit-ads",
                component: () => import("@/views/ads/editAds.vue"),
                meta: {access_permissions: ["1", "2"], title: "تعديل الإعلان"}
            },
            {
                path: "/uploadCenter",
                name: "uploadCenter",
                component: () => import("@/views/uploadCenterPage.vue"),
                meta: {access_permissions: ["1", "2", "3"], title: "مركز الرفع"}
            },

            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/profile/index.vue"),
                meta: {access_permissions: ["1", "2", "3", "4", "5"], title: "الملف الشخصي"}
            },
            {
                path: "/change-password",
                name: "changePassword",
                component: () => import("@/views/profile/ChangePassword.vue"),
                meta: {access_permissions: ["1", "2", "3", "4", "5"], title: "تغيير كلمة المرور"}
            },
            {
                path: "/pages",
                name: "pages",
                component: () => import("@/views/pages/index.vue"),
                meta: {access_permissions: ["1", "2"], title: "الصفحات"}
            },
            {
                path: "/add-page",
                name: "addPage",
                component: () => import("@/views/pages/add.vue"),
                meta: {access_permissions: ["1", "2"], title: "إضافة صفحة جديدة"}
            },
            {
                path: "/edit-page/:id",
                name: "editPage",
                component: () => import("@/views/pages/edit.vue"),
                meta: {access_permissions: ["1", "2"], title: "تعديل الصفحة"}
            },
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
        meta: {title: "تسجيل الدخول"}

    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        meta: {title: "404"},
        component: () =>
            import(/* webpackChunkName: "Not Found" */ "../views/404.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
});

router.beforeEach(function (to, from, next) {
    document.title = 'لوحة تحكم زينة - '+ to.meta.title as string;
    if (to.name !== "Login" && !localStorage.getItem("access_token")) {
        next({name: "Login"});
    } else if (localStorage.getItem("access_token")) {
        if (to.name === "Login") {
            next({name: "all-news"})
        } else {
            //@ts-ignore
            if (!to.meta.access_permissions?.includes(localStorage.getItem("type_id"))) {
                next({name: "all-news"})
            } else {
                next()
            }
        }
    } else {
        next()
    }


});

export default router;
