import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_REMOTE_URL,
});

service.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
service.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
service.defaults.headers.common["sameSite"] = "None";

//service.defaults.timeout = 30000;

service.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

service.interceptors.response.use(
  (response) => {
    // NProgress.done()
    return response;
  },
  (error) => {
    if (!error.response) {
      toast.error("عذرا ، خطأ في الخادم ، يرجى الاتصال بالمسؤول. \n");
      // console.error('Sorry, Error on the server, please contact the administrator.')
      return Promise.reject(error);
    }else {
      if(error.response.status === 500){
        toast.error(error.response.data.message)
      }
    }

    return Promise.reject(error);
  }
);

export default service;
