<template>
  <router-view />
</template>

<script setup>
 import {onMounted} from "vue";

 onMounted(()=>console.log(`You are on ${process.env.VUE_APP_MODE} env`))

 let resizeTimer;
 window.addEventListener("resize", () => {
   document.body.classList.add("resize-animation-stopper");
   clearTimeout(resizeTimer);
   resizeTimer = setTimeout(() => {
     document.body.classList.remove("resize-animation-stopper");
   }, 400);
 });

</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@vueform/multiselect/themes/default.css";

</style>
