import {getFeatures} from "../services/auth";

const NAV_ITEMS              =
        [
          {
            index             : '/',
            key               : 'dashboard',
            icon              : '<i class="las la-home" style="font-size: 1.25rem"/>',
            title             : 'لوحة التحكم',
            path              : 'dashboard',
            hasChild          : false,
            access_permissions: ["1", "2", "3"]
          },
          {
            index             : '2',
            key               : 'news',
            icon              : '<i class="far fa-newspaper"/>',
            title             : 'الأخبار',
            hasChild          : true,
            access_permissions: ["1", "2", "3"],
            children          : [
              {
                index: '1',
                title: 'جميع الأخبار',
                path : 'all-news',
              },
              {
                index: '3',
                title: 'التصنيفات',
                path : 'categories',
              },
              {
                index: '4',
                title: 'سلة المحذوفات',
                path : 'deleted-news',
              },
            ]
          },
          {
            index             : '3',
            key               : 'articles',
            icon              : '<i class="far fa-sticky-note"/>',
            title             : 'المقالات',
            hasChild          : false,
            path: 'articles',
            access_permissions: ["1", "2", "3", "4", "5"],
          },
          {
            index             : '4',
            key               : 'playList',
            icon              : '<i class="las la-video" style="font-size: 1.43rem"/>',
            title             : 'مكتبة الفيديو',
            hasChild          : true,
            access_permissions: ["1", "2", "3"],
            children          : [
              {
                index: '1',
                title: 'قوائم التشغيل',
                path : 'play-list',
              },
              {
                index: '2',
                title: 'إضافة قائمة تشغيل',
                path : 'all-news',
              },
              {
                index: '3',
                title: 'إضافة فيديو',
                path : 'all-news',
              },
            ]
          },
          {
            index             : '5',
            key               : 'albums',
            icon              : '<i class="far fa-images"/>',
            title             : 'مكتبة الألبومات',
            hasChild          : false,
            path: 'albums',
            access_permissions: ["1", "2", "3"],
          },
          {
            index             : '/breaking-news',
            key               : 'breakingNews',
            icon              : '<i class="las la-fire-alt" style="font-size: 1.5rem;"/>',
            title             : 'الأخبار العاجلة',
            hasChild          : false,
            path : 'breaking-news',
            access_permissions: ["1", "2", "3"],
          },
          {
            index             : '/tags',
            key               : 'tags',
            icon              : '<i class="las la-tags" style="font-size: 1.3em"/>',
            title             : 'الأوسمة',
            path              : 'all-tags',
            hasChild          : false,
            access_permissions: ["1", "2", "3", "4"]
          },
          {
            index             : '/live-broadcast',
            key               : 'liveBroadcasts',
            icon              : '<i class="las la-broadcast-tower" style="font-size: 1.3em"/>',
            title             : 'البث المباشر',
            hasChild          : true,
            access_permissions: ["1", "2", "3"],
            children          : [
              {
                index: '1',
                title: 'عرض جميع البثوث',
                path : 'live-broadcasts',
              },
              {
                index: '2',
                title: 'إضافة بث جديد',
                path : 'add-live-broadcast',
              },
            ]
          },
          {
            index             : '/users',
            key               : 'users',
            icon              : '<i class="fas fa-users"/>',
            title             : 'المستخدمين',
            path: 'users',
            hasChild          : false,
            access_permissions: ["1", "2"],

          },
          {
            index             : '/pages',
            key               : 'pages',
            icon              : '<i class="lar la-file-alt" style="font-size: 1.3em"/>',
            title             : 'الصفحات',
            hasChild          : false,
            path: 'pages',
            access_permissions: ["1", "2"],
          },
          {
            index             : '/ads',
            key               : 'ads',
            icon              : '<i class="las la-headset"/>',
            title             : 'الإعلانات',
            hasChild          : true,
            access_permissions: ["1", "2"],
            children          : [
              {
                index: '/ads',
                title: 'الوحدات الإعلانية',
                path : 'ads',
              },
              {
                index: '/places',
                title: 'الأماكن الإعلانية',
                path : 'places',
              },
            ]
          },
          {
            index             : '/uploadCenter',
            key               : 'uploadCenter',
            icon              : '<i class="las la-cloud-upload-alt" style="font-size: 1.3rem"/>',
            title             : 'مركز الرفع',
            path              : 'uploadCenter',
            hasChild          : false,
            access_permissions: ["1", "2", "3"]
          },
          {
            index             : '/settings',
            key               : 'settings',
            icon              : '<i class="las la-cog" style="font-size: 1.3rem"/>',
            title             : 'إعدادات الموقع',
            path              : 'settings',
            hasChild          : false,
            access_permissions: ["1", "2"]
          },
        ];
export const NavigationStore = {
  state    : {
    filteredFeatures: [],
    featuresKeys: {},
    isLoading: true,
    logo: '',
    logo_alt: ''
  },
  getters  : {
    //navItems        : state => state.navItems,
    filteredFeatures: state => state.filteredFeatures,
    featuresKeys: state => state.featuresKeys,
    isLoading: state=>state.isLoading,
    logo: state => {
      return {img: state.logo, logo_alt: state.logo_alt}
    }
  },
  mutations: {
    SET_FEATURES(state, data)
    {
      return state.filteredFeatures = data
    },
    SET_FEATURED_KEYS(state,data) {
      return state.featuresKeys = data
    },
    SET_IS_LOADING(state,data) {
      return state.isLoading = data
    },
    SET_LOGO(state,data) {
      return state.logo = data
    },
    SET_LOGO_ALT(state,data) {
      return state.logo_alt = data
    },

  },
  actions  : {
    async fetchFeatures({commit, getters})
    {
      if (!getters.filteredFeatures.length)
      {
        const result = await getFeatures()
        commit('SET_IS_LOADING', false)
        let featuresKeys = new Map(Object.entries(result.data));
        let filtered     = NAV_ITEMS.filter(item => {
          item.title = featuresKeys.get(item.key)
          return featuresKeys.has(item.key)
        });
        commit('SET_FEATURED_KEYS', result.data);
        commit('SET_FEATURES', filtered);
        commit('SET_LOGO', result.data.logo);
        commit('SET_LOGO_ALT', result.data.logo_alt);
      }
    },
    resetFeatures({commit}) {
      commit('SET_FEATURED_KEYS', []);
      commit('SET_FEATURES', {});
      commit('SET_LOGO', '');
      commit('SET_LOGO_ALT', '');
    }
  }

}
