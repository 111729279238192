import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {NavigationStore} from './navigation'
import {CategoriesStore} from './categories'
export default createStore({
  state: {
    isUploadCenterOpen: false,
    user: null
  },
  mutations: {
    SET_IS_OPEN(state,data){
      state.isUploadCenterOpen = data
    },
    SET_USER(state,data) {
      state.user = data
    }
  },
  getters: {
    isUploadCenterOpen: state => state.isUploadCenterOpen,
    getCurrentUser: state => state.user
  },
  actions: {
    fetchUser({commit},user) {
      if(user){
        commit('SET_USER',user)
      }else {
        commit('SET_USER',null)
      }
    }
  },
  modules: {
    NavigationStore,
    CategoriesStore
  },

  plugins: [createPersistedState()],

});
