import {getNewsCategories} from "@/services/news";

export const CategoriesStore = {
  state    : {
    categories: null
  },
  actions  : {
    async fetchCategories({commit, getters})
    {
      try
      {
        if (!getters.categories)
        {
          const result = await getNewsCategories()
          await commit('SET_CATEGORIES', result.data)
        }
      } catch (e)
      {
        console.log(e)
      }
    },
    resetCategories({commit}) {
       commit('SET_CATEGORIES', null)
    }
  },
  mutations: {
    SET_CATEGORIES(state, data)
    {
      state.categories = data
    }
  },
  getters  : {
    categories: state => state.categories
  }
}
