import request from "@/utils/request";
import axios from "axios";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}

export function getProfileData(user_id){
  return request(
    {
      url: `user/${user_id}`,
      method: "get"
    }
  )
}

export function updateProfile(user_id,data) {
  return request(
    {
      url: `user/update/${user_id}`,
      method: 'put',
      data
    }
  )
}

export function changePassword(data) {
  return request(
    {
      url: 'user/changePassword',
      method: 'post',
      data
    }
  )
}


export function getFeatures(){
  return axios.get('https://saudihoney.net/api/features')
}
