import request from "@/utils/request";

export function getNewsCategories()
{
  return request({
                   url   : "/category",
                   method: "get",
                 });
}

export function fetchSearchTags(key)
{
  return request(
    {
      url   : `/search-tags/${key}`,
      method: "get"
    }
  )
}

export function fetchAllNews(page = 1)
{
  return request(
    {
      url   : `/news?page=${page}`,
      method: "get"
    }
  )
}

export function createNews(data)
{
  return request(
    {
      url   : "/news",
      method: "post",
      data
    }
  )
}

export function saveDraft(data)
{
  return request(
    {
      url   : "/news/draft",
      method: "post",
      data
    }
  )
}

export function searchNews(data)
{
  return request(
    {
      url   : "/news/search",
      method: "post",
      data
    }
  )
}

export function deleteSingleNews(news_id)
{
  return request(
    {
      url   : `/news/${news_id}`,
      method: "delete"
    }
  )
}

export function deletedNews(page = 1) {
  return request(
    {
      url: `/deleted?page=${page}`,
      method: "get"
    }
  )
}

export function deactivateNews(news_id)
{
  return request(
    {
      url   : `/news/status/${news_id}`,
      method: "get"
    }
  )
}

export function getSingleNews(news_id)
{
  return request(
    {
      url   : `/news/${news_id}`,
      method: "get"
    }
  )
}

export function updateNews(news_id,data){
  return request(
    {
      url: `/news/${news_id}`,
      method: "put",
      data
    }
  )
}

export function getDeletedNews(page = 1) {
  return request(
    {
      url: `deleted?page=${page}`,
      method: "get"
    }
  )
}

export function restoreSingleNews(news_id) {
  return request(
    {
      url: `/restore/${news_id}`,
      method: "get"
    }
  )
}
