import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast, {PluginOptions,POSITION } from "vue-toastification";
import VCalendar from 'v-calendar';
import ElementPlus from "element-plus";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import Pagination from 'v-pagination-3';

import "element-plus/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/style.css";
import "vue-toastification/dist/index.css";
import 'v-calendar/dist/style.css';
import {defineRule} from "vee-validate";
import {confirmed, required} from "@vee-validate/rules";

const filterBeforeCreate = (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
        return false;
    }
    return toast;
}

defineRule('required', value => {
    if (!value || !value.length) {
        return 'هذا الحقل مطلوب';
    }
    return true;
});

// @ts-ignore
defineRule('confirmed', (value, [target], ctx) => {
    if (value === ctx.form[target]) {
        return true;
    }
    return 'كلمتا المرور غير متطابقتين';
});


const options: PluginOptions = {
    filterBeforeCreate,
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: true,
};

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Toast, options);
app.use(VCalendar, {});
app.component('pagination', Pagination);

initInlineSvg(app);

app.mount("#app");
